import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import Logo from '../Images/logo.png'
import { useTranslation } from "react-i18next";

import '../Styles/Services/Main.css'

import ImageSlider from "react-simple-image-slider";

// Images

import i9874 from '../Images/services/ADAM9874.webp'
import i9868 from '../Images/services/ADAM9868.webp'
import i9597 from '../Images/services/ADAM9597.webp'
import i9539 from '../Images/services/ADAM9593.webp'
import i9591 from '../Images/services/ADAM9591.webp'
import i9589 from '../Images/services/ADAM9589.webp'
import i9518 from '../Images/services/ADAM9518.webp'
import i9531 from '../Images/services/ADAM9531.webp'
import i9564 from '../Images/services/ADAM9564.webp'
import i9565 from '../Images/services/ADAM9565.webp'
import i9609 from '../Images/services/ADAM9609.webp'
import i9675 from '../Images/services/ADAM9675.webp'
import i9553 from '../Images/services/ADAM9553.webp'
import i9692 from '../Images/services/ADAM9692.webp'
import i9700 from '../Images/services/ADAM9700.webp'
import i9702 from '../Images/services/ADAM9702.webp'
import i9848 from '../Images/services/ADAM9848.webp'
import i9879 from '../Images/services/ADAM9879.webp'
import i9880 from '../Images/services/ADAM9880.webp'
import i9536 from '../Images/services/ADAM9536.webp'
import i9893 from '../Images/services/ADAM9893.webp'
import i9889 from '../Images/services/ADAM9889.webp'
import i9674 from '../Images/services/ADAM9674.webp'
import i9601 from '../Images/services/ADAM9601.webp'
import i9671 from '../Images/services/ADAM9671.webp'
import i9668 from '../Images/services/ADAM9668.webp'
import i9644 from '../Images/services/ADAM9644.webp'
import i9833 from '../Images/services/ADAM9833.webp'
import i9769 from '../Images/services/ADAM9769.webp'
import i9713 from '../Images/services/ADAM9713.webp'
import i9740 from '../Images/services/ADAM9740.webp'
import i9735 from '../Images/services/ADAM9735.webp'
import i9764 from '../Images/services/ADAM9764.webp'
import i9762 from '../Images/services/ADAM9762.webp'
import i9747 from '../Images/services/ADAM9747.webp'



const Services = () => {

   const {t} = useTranslation()

    const { pathname } = useLocation();
    const nav = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [pathname]);


    const [,setImageNum] = useState(1);
    const sliderImages1 = [
       { url: i9874 } , { url: i9868 }, { url: i9597 }, { url: i9539 }, { url: i9591 }
   ];

   const sliderImages2 = [
    { url: i9589 } , { url: i9518 }, { url: i9531 }, { url: i9564 }, { url: i9565 }
   ];

   const sliderImages3 = [
    { url: i9609 } , { url: i9675 }, { url: i9564 }, { url: i9553 }, { url: i9565 }
   ];
   
   const sliderImages4 = [
    { url: i9692 } , { url: i9700 }, { url: i9702 }, { url: i9848 }, { url: i9879 }, { url: i9880 }
   ];

   const sliderImages5 = [
    { url: i9536 } , { url: i9893 }, { url: i9889 }, { url: i9674 }, { url: i9609 }, { url: i9601 }
   ];

   const sliderImages6 = [
    { url: i9675 }, { url: i9609 } , { url: i9674 }, { url: i9671 }, { url: i9668 }, { url: i9644 }
   ];

   const sliderImages7 = [
    { url: i9713 }, { url: i9740 } , { url: i9769 }
   ];

   const sliderImages8 = [
    { url: i9713 }, { url: i9735 } , { url: i9769 }
   ];

   const sliderImages9 = [
    { url: i9713 } , { url: i9769 }
   ];

   const sliderImages10 = [
    { url: i9764 } , { url: i9762 }, { url: i9747 }
   ];

   const sliderImages11 = [
    { url: i9848 } , { url: i9880 }, { url: i9879 }
   ];


    return(
        <React.Fragment>
            <div className="sec-1S">

                <h1>{t('Services')}</h1>

            </div>

            <div className="sec-2S">

                <Fade bottom distance="5%"> 

                <div>

                    
                <h1>{t('S1')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages1}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                    
                <h1>{t('S4')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages2}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                    
                <h1>{t('S12')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages10}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                <h1>{t('S5')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages4}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                 <div>

                <h1>{t('S11')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages9}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                <h1>{t('S14')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages11}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                 <div>

                    
                <h1>{t('S6')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages5}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                <h1>{t('S9')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages7}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                    
                <h1>{t('S2')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages3}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                <h1>{t('S10')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages8}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                <h1>{t('S3')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages3}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                    
                <h1>{t('S7')}</h1>

                <ImageSlider
                    width={300}
                    height={400}
                    images={sliderImages6}
                    showBullets={false}
                    style={{background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    showNavs={false}
                    autoPlay={true} 
                    onStartSlide = {(index) => {
                       setImageNum(index);
                    }}
                       autoPlayDelay = {2} 
                 />

                </div>

                <div>

                    
                <h1>{t('S13')}</h1>

                <img
                    src={i9769}
                    alt="image2"
                    style={{background: '#fff',height: 'auto', width: '300px' , borderRadius: 15 , display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    onContextMenu={(e) => {e.preventDefault()}}
                    onDragStart={(e) => {e.preventDefault()}}
      
                 />

                </div>

                <div>

                    
                <h1>{t('S8')}</h1>

                <img
                    width={300}
                    src={i9833}
                    alt="image1"
                    style={{background: '#fff', height: 'auto', borderRadius: 15 , display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}
                    onContextMenu={(e) => {e.preventDefault()}}
                    onDragStart={(e) => {e.preventDefault()}}
                 />

                </div>

                </Fade>

            </div>

            <section className="sec-5">
            
                <img src={Logo} alt="image1" onContextMenu={(e)=>e.preventDefault()} onDragStart={(e)=>{e.preventDefault()}}/>

                <button onClick={() => {nav('/Terms')}}>{t('Terms')}</button>

                <p>Powered By <a href="https://devstation.pro/">devstation.pro</a></p>


            </section>
        </React.Fragment>
    )
}

export default Services